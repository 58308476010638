import React from 'react'
import { graphql, Link } from 'gatsby'

import { Image, FormatDate } from '../../utilities'
import ReactMarkdown from 'react-markdown'
import { Layout } from '../../components'
// import { ArticlesGrid } from '../../components/main/blocks'

import * as styles from './article.module.scss'
import { sanitizeContent } from '../../utilities/sanitizeContent'

export default ({
  data: {
    blogArticlesJson
  },
}) => {

  const article = sanitizeContent(blogArticlesJson)

  const {
    category,
    date,
    heroImage,
    title,
    tags,
    body,
    seo_image
  } = article

  return (
    <Layout title={title} metaTags={tags} seo_image={seo_image} description={title}>
      <article className={styles.article}>
        <Image src={heroImage} className={styles.hero} container/>
        <p className={styles.date}>Posted <span><FormatDate date={date} /></span></p>
        <h1 className={styles.title}>{title}</h1>
        <ReactMarkdown className={styles.body}>{body}</ReactMarkdown>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
query ($slug: String!) {
  blogArticlesJson(slug: {eq: $slug}) {
    category
    date
    heroImage
    title
    tags
    body
    seo_image
  }
}
`